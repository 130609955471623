




































import { Component, Prop, Watch } from 'vue-property-decorator';

import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { mixins } from 'vue-class-component';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import TabMenuItemComponent from '@/components/TabMenuItemComponent.vue';
import TabMenuItemParams from '@/utils/types/TabMenuItemParams';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';

@Component({
  components: { TabMenuItemComponent },
})
export default class ExhibitorPortalHeaderWidget extends mixins(
  VueRegisterStoreWidget,
  VueBaseWidget,
  CompanyCmsProfileBaseWidget,
) {
  protected baseStoreName = 'ExhibitorPortalHeaderWidgetStore';

  @Prop({ default: null })
  private title!: string;

  @Prop({
    required: false,
    default: () => [],
  })
  private tabConfigs!: TabMenuItemParams[];

  created(): void {
    this.setDataConfig();
  }

  updated(): void {
    this.setContentWrapperHeight();
  }

  @Watch('breakpoint.value')
  private setContentWrapperHeight(): void {
    if (this.$el) {
      if (this.tabConfigs.length === 0) {
        if (this.isMobile) {
          (this.$el as HTMLElement).style.setProperty('--content-wrapper-height', '-1rem');
        } else {
          (this.$el as HTMLElement).style.setProperty('--content-wrapper-height', '1rem');
        }
      } else if (this.isMobile) {
        (this.$el as HTMLElement).style.setProperty('--content-wrapper-height', '2.75rem');
      } else {
        (this.$el as HTMLElement).style.setProperty('--content-wrapper-height', '4.25rem');
      }
    }
  }
}
